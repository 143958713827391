import React from "react";
import { Box, Typography } from "@mui/material";
import MarkLatex from "../../atom/Marklatex/MarkLatex";
import { styled } from "@mui/material/styles";

/** Material UI - Custom elements */
const AnswerTextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const AnswerText = styled(MarkLatex)(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontFamily: "Noto Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "25px",
  maxWidth: "100%",
  "& img": {
    maxWidth: "100%",
  },
  "& p": {
    maxWidth: "100%",
  },
  "& pre": {
    textWrap: "wrap",
  },
  "& .math": {
    overflowX: "auto",
    overflowY: "visible",
    scrollbarWidth: "thin",
  },
  "& blockquote": {
    color: theme.palette.appcolors.grey,
    borderLeft: `2px solid ${theme.palette.appcolors.grey}`,
  },
  [theme.breakpoints.down(900)]: {
    fontWeight: 500,
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "14px",
  },
}));

const TitleText = styled("p")(({ theme, mqtitle }) => ({
  color: theme.palette.appcolors.black,
  fontFamily: "Noto Sans",
  fontSize: mqtitle === "mqtitle" ? "16px" : "21px",
  fontStyle: "normal",
  fontWeight: mqtitle === "mqtitle" ? 700 : 400,
  lineHeight: "25px",
  [theme.breakpoints.down(900)]: {
    fontWeight: 400,
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "21px",
  },
}));

const AnswerStatement = ({
  answer,
  image,
  titleAnswerClassName,
  AnswerTextClassName,
} = {}) => {
  //Frontend fix, maybe move to backend later? Trello ticket about the problem: https://trello.com/c/F7i6zYry
  const addLineBreaker = answer
    ? answer
        .split("\n")
        .map((line, index) => {
          if (line.startsWith(">")) {
            return index === 0 ? line + "\n>" : ">\n" + line;
          } else if (line.trim() === "") {
            return "&#160;";
          } else {
            return line;
          }
        })
        .join("\n")
    : null;
  const removeSpaces = answer
    ? addLineBreaker?.replace(/^\s+(?=\$)/gm, "")
    : null;
  return (
    <>
      <AnswerTextContainer
        sx={{
          maxWidth: image ? "auto" : "100%",
        }}
      >
        {titleAnswerClassName ? (
          <TitleText mqtitle={titleAnswerClassName}>Förklaring:</TitleText>
        ) : (
          <Typography
            variant="h5"
            component="h5"
            style={{
              fontSize: "1rem",
              fontWeight: 500,
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            Förklaring:
          </Typography>
        )}
        {AnswerTextClassName ? (
          <AnswerText content={removeSpaces} />
        ) : (
          <Typography
            variant="body1"
            component="div"
            sx={{ fontSize: "0.875rem" }}
          >
            <div className="Explaination">
              {" "}
              <MarkLatex content={removeSpaces} />
            </div>
          </Typography>
        )}
      </AnswerTextContainer>
      {image && (
        <Box
          mt={2}
          style={{
            marginTop: "2rem",
          }}
        >
          <img style={{ height: 110 }} src={image} alt="" />
        </Box>
      )}
    </>
  );
};

export default AnswerStatement;
