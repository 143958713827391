/** Libraries */
import React, { useEffect, useMemo, useState } from "react";
import { Box, Radio } from "@material-ui/core";

/** Atoms */
import {
  StyledCorrectIcon,
  StyledRadiusCheckedIcon,
  StyledRadiusUnCheckedIcon,
  StyledRadiusOnHoverIcon,
  StyledWrongIcon,
} from "../../../../../atom/RadioIcons/RadioIcons";

/** Molecules */
import OptionsSQ from "../../../../../molecule/OptionsComponents/OptionsSQ/OptionsSQ";
import useWindowDimensions from "../../../../../molecule/WindowDimensions/dimension";

/** Organisms */
import { SQDescription } from "../../../../Quiz/descriptions/SQDescription/SQDescription";
import { ExamBottomBar } from "../../../../BottomBars/ExamBottomBar";

/** Templates */
import { SQTemplate } from "../../../../../template";

const ExamBodySQ = (props) => {
  const [onHover, setOnhover] = useState();

  const sectionTitle = useMemo(
    () => props?.currentQuestion?.sectionCategories?.title,
    [props?.currentQuestion]
  );

  /** Returns the options radius buttons for SQ*/
  const showOptionsNewStyles = (question, curentOption, optionIndex) => {
    if (question.answer && question.answer.option === curentOption._id) {
      return <Radio disabled checked={false} icon={<StyledCorrectIcon />} />;
    } else if (question.answer && curentOption._id === question?.optionId) {
      return <Radio disabled checked={false} icon={<StyledWrongIcon />} />;
    } else if (question.answer && curentOption._id !== question?.optionId) {
      return (
        <Radio disabled checked={false} icon={<StyledRadiusUnCheckedIcon />} />
      );
    }

    if (
      // optionIndex === question.selectedIndex ||
      optionIndex === question.selectedOptionIndex
    ) {
      return (
        <Radio
          color="primary"
          checked={true}
          icon={<StyledRadiusUnCheckedIcon />}
          checkedIcon={<StyledRadiusCheckedIcon />}
        />
      );
    } else {
      return (
        <Radio
          color="primary"
          checked={false}
          icon={
            onHover && curentOption._id === onHover ? (
              <StyledRadiusOnHoverIcon />
            ) : (
              <StyledRadiusUnCheckedIcon />
            )
          }
        />
      );
    }
  };

  return (
    <>
      <SQTemplate
        description={
          <SQDescription
            doesSparaButtonExists={true}
            isQuestionFlagged={
              props?.quiz.question[props?.currentIndex].isFlaged
            }
            flagQuestion={props?.flagQuestion}
            sparaSaveButtonRef={props?.saveButtonRef}
            sectionTitle={sectionTitle}
            questionDescription={props?.currentQuestion?.questionStatement}
            questionIndications={[
              props?.currentQuestion?.information1,
              props?.currentQuestion?.information2,
            ]}
            questionImage={props?.currentQuestion?.questionStatement?.includes(
              "hp-appen.s3.eu-north-1.amazonaws.com"
            )}
          />
        }
        options={
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <OptionsSQ
              questionTypeTitle={props?.currentQuestion.sectionCategories.title}
              onHover={onHover}
              onHoverLeave={() => setOnhover(null)}
              onhover={(optionId) => setOnhover(optionId)}
              question={props?.questionNewStructure}
              SelectOption={(item, index) => props?.SelectFunc(item._id, index)}
              showOptions={showOptionsNewStyles}
            />
          </Box>
        }
      ></SQTemplate>
      <ExamBottomBar
        isLastQuestion={props?.isLastQuestion}
        handleLamnaIn={props?.handleLamnaIn}
        setCurrentIndex={props?.setCurrentIndex}
        currentIndex={props?.currentIndex}
        quiz={props?.quiz}
      />
    </>
  );
};

export default ExamBodySQ;
