/** Libraries */
import { Box } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";

/** Atoms */
import MarkLatex from "../../../atom/Marklatex/MarkLatex";

/** Styles */
export const useStyles = makeStyles((theme) => ({
  pulsatingDot: {
    position: "absolute",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    alignSelf: "start",
    animation: "$pulse 1.5s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.8)",
    },
    "50%": {
      transform: "scale(1.2)",
    },
    "100%": {
      transform: "scale(0.8)",
    },
  },
}));

export const MainContainer = styled(Box)(({ theme, isMQ, isuser }) => ({
  display: "flex",
  width: isuser ? "90%" : "100%",
  flexDirection: isMQ ? "column" : "row",
  justifyContent: isMQ ? "center" : isuser ? "flex-end" : "flex-start",
  alignItems: isMQ ? "center" : isuser ? "end" : "start",
  transition: "opacity 0.5s ease, transform 0.5s ease",
  boxSizing: "border-box",
  padding: "10px 0px",
  backgroundColor: isuser && theme.palette.appcolors.primaryColor10,
  borderRadius: isuser && "16px 0px 16px 16px",
  marginLeft: isuser && "auto",
}));

export const AnswerText = styled(MarkLatex)(({ theme, darkmode }) => ({
  color: theme.palette.appcolors.black,
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  lineHeight: "25px",
  letterSpacing: "-0.176px",
  maxWidth: "100%",
  transition: "opacity 0.5s ease, transform 0.5s ease",
  wordBreak: "break-word",
  "& img": {
    maxWidth: "100%",
    filter: darkmode && "invert(0.9)",
    marginBottom: "2rem",
    minHeight: "0",
    maxHeight: "400px",
  },
  "& p": {
    maxWidth: "100%",
  },
  "& .math": {
    overflowX: "auto",
    overflowY: "visible",
    scrollbarWidth: "thin",
  },
  "& blockquote": {
    color: theme.palette.appcolors.grey,
    borderLeft: `2px solid ${theme.palette.appcolors.grey}`,
  },
  "& code": {
    wordBreak: "break-word",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
  },
  [theme.breakpoints.down(600)]: {
    fontWeight: 500,
    fontSize: "14px",
  },
  [theme.breakpoints.down(400)]: {
    fontSize: "12px",
  },
}));

export const ContentContainer = styled(Box)(({ theme, isMQ }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "start",
  padding: isMQ ? "0px 0px 0px 36px" : "6px",
  marginTop: isMQ && "-16px",
}));

export const ItemsContainer = styled("div")(
  ({ theme, isFeedbackDisabled, isuser }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingBottom: isFeedbackDisabled && "20px",
    padding: isuser && "0px 10px",
  })
);

export const MQExplanationTitle = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontFamily: "Noto Sans",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "25px",
  [theme.breakpoints.down(900)]: {
    fontWeight: 400,
  },
}));

export const AvatarContainer = styled("div")(({ theme }) => ({
  height: "23px",
  display: "flex",
  padding: "9px",
  alignItems: "flex-start",
  gap: "13px",
  alignSelf: "stretch",
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: "19px",
  height: "19px",
  fontSize: "12px",
}));

export const AvatarName = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontFamily: "Noto Sans",
  fontSize: "12px",
  lineHeight: "22.721px",
  paddingBottom: "8px",
}));
