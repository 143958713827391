import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const MainContainer = styled(Box)(({ theme, yScrollEnabled }) => ({
  display: "flex",
  width: "100%",
  padding: "3rem",
  height: "auto",
  minHeight: "284px",
  flexDirection: "column",
  alignItems: "flex-start",
  maxHeight: yScrollEnabled ? "60vh" : "auto",
  overflowY: yScrollEnabled ? "auto" : "visible",
  justifyContent: yScrollEnabled ? "flex-start" : "center",
  border: `1px solid ${theme.palette.appcolors.grey4}`,
  borderRadius: "16px",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  boxSizing: "border-box",
  [theme.breakpoints.down(800)]: {
    padding: "2rem",
  },
  [theme.breakpoints.down(600)]: {
    padding: "1rem",
    paddingTop: "2rem",
  },
  "& p, & h3": {
    textAlign: "start",
  },
  "& blockquote": {
    color: theme.palette.appcolors.grey,
    borderLeft: `2px solid ${theme.palette.appcolors.grey}`,
  },
}));

const QuestionWrapper = ({ children, questionTypeTitle, ...props }) => {
  return (
    <MainContainer {...props}>
      {/*  Here we should use QuestionStatement as a children*/}
      {children}
    </MainContainer>
  );
};

export default QuestionWrapper;
